.recipes, .grid {
	display: flex;
	flex-wrap: wrap;
	.recipe, .grid-item {
		flex: 1 0 300px;
		height: 300px;
		display: inline-block;
		background-position: center;
		background-size: cover;
		margin: 5px 7px;

		a {
			width: 100%;
			height: 100%;
			display: block;
			background: rgba(0, 0, 0, .2);
			color: #fff;
			text-decoration: none;
			text-align: center;
			vertical-align: text-bottom;
			position: relative;
			transition:      all 0.2s ease;
			font-size: 1.1em;
			font-weight: 700;

			span {
				position: absolute;
				bottom: 5px;
				width: 100%;
				left: 0;
				text-shadow: 1px 1px 1px #000;
			}

			&:hover {
				background: none;
			}
		}
	}
}
