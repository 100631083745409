@font-face {
  font-family: 'mellony_dry_brushregular';
  src: url('/fonts/mellony_dry_brush-webfont.woff2') format('woff2'),
  url('/fonts/mellony_dry_brush-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html , body {
  padding: 0;
  margin: 0;
  line-height: 1.8em;
}

body, input, textarea {
  font-family: 'Fira Sans', sans-serif;
  color: #222;
  -webkit-font-smoothing: antialiased;
}

h1 {
  font-size: 1.8em;
  margin: 0;
  padding: 0;
  line-height: 1.3em;
}

h2 {
  font-size: 1.4em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $serif;
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

time {
  color: $light-grey;
}

header {
  text-align: center;
  padding: 80px 0 60px 0;
}

img {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto
}

ol li + li {
  margin: 20px 0 0 0;
}

footer {
  color: $light-grey;
  text-align: center;
  padding: 20px 0;
  font-size: .8em;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
}

a {
  text-decoration: none;
  color: $light-grey;
}

table {
  border: 1px solid #efefef;
  text-align: center;
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;

  thead {
    border-bottom: 5px solid #efefef;
    height: 50px;
    padding: 5px 0;
    font-family: $serif;
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  td, th {
    height: 50px;
    border: 1px solid #efefef;
  }

  tr:nth-child(even) {
    background-color: #F8F8F8;
  }
}

html {
  scroll-behavior: smooth;
}

:target:before {
  content: "";
  display: block;
  height: 100px;
  margin: -100px 0 0;
}