.container {
	max-width: 1100px;
	margin: 0 auto;
}

.main-content {
	@media #{$desktop} {
		display: flex;
	}
	padding: 0 30px 50px 30px;
}

.content {
	@media #{$desktop} {
		flex: 1;
	}
}

#instagram-feed {
	a {
		img {
			max-width: 15vw;
			max-height: 15vw;
		}
	}
}

.info {
  background: #F8F8F8;
  border-left: 4px solid #222;
  padding: 1px 0px;
  margin: 0;

  p {
    margin-left: 10px;

    strong {
      color: #222;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
}

.caption {
  text-align: center;
  font-size: .8rem;
  color: $light-grey;
  font-style: italic;
}

.side {
  max-width: 50%;
  float: left;
}

.side-right {
  max-width: 50%;
  float: right;
}

.search {
  margin-bottom: 50px;

  img {
    height: 15px;
    float: right;
    margin-top: 10px;
  }
}

/* Search results container */
.algolia__initial-content {
  display:block;
}
.algolia__initial-content--hidden {
  display:none;
}
.algolia__search-content {
  display:none;
}
.algolia__search-content--active {
  display:block;
}

.algolia__result-link .algolia__result-highlight {
    background-color: #F8F8F8;
}

.algolia__result-text .algolia__result-highlight {
    font-weight:bold;
}

.product-details {
  dl {
    overflow: visible;

    @media #{$desktop} {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
    }
  }

  dt {
    font-family: $serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 4px 0;
    overflow: hidden;

    @media #{$desktop} {
      border-top: 5px solid #efefef;
      flex: 0 0 25%;
    }
  }

  dd {
    border-top: 1px solid #efefef;
    font-size: 95%;
    margin-left: auto;
    overflow: hidden;

    @media #{$desktop} {
      flex:0 0 75%;
    }

    blockquote {
      position: relative;
    }

    blockquote:before {
      position: absolute;
      content: open-quote;
      font-size: 3em;
      margin-left: -0.5em;
      margin-top: 0.1em;
      color: $light-grey;
      font-family: $serif;
    }

    blockquote:after {
      position: absolute;
      content: close-quote;
      font-size: 3em;
      bottom: 0;
      right: 0;
      margin-right: -0.5em;
      margin-bottom: -0.1em;
      color: $light-grey;
      font-family: $serif;
    }

    blockquote p {
      display: inline;
    }
  }
}

.product-review {
  border: 1px solid #efefef;
  margin: 2em 0;

  .review-title {
    margin: 10px;
    text-align: center;
  }

  ul.review-list {
    list-style-type: none;
    padding: 0;
    justify-content: space-between;
    margin: 0;
    border-top: 1px solid #efefef;

    @media #{$desktop} {
      display: flex;
    }
  }

  .review-list li {
    padding: 10px 0;
    text-align: center;

    border-bottom: 1px solid #efefef !important;

    @media #{$desktop} {
      width: 20%;
    }

    @media #{$desktop} {
      width: 100%;
    }

    span {
      display: block;
      font-family: $serif;
    }
  }

  .review-list li:not(:last-child) {
    border-right: 1px solid #efefef;
  }

  .has-ul li:not(:last-child), article ul li:not(:last-child), aside ul li:not(:last-child) {
    border-bottom: 1px dotted;
  }

  .review-total-wrapper {
    text-align: center;
    padding-bottom: 1em;
  }

  .review-total-box {
    font-family: $serif;
    font-size: 2em;
    padding-bottom: 5px;
    display: block;
  }

  .review-desc {
    padding: 0 15px;
    font-size: 95%;
  }
  
  h4 {
    text-align: center;
  }
}

.video {
  align-items: center;
  padding: 10px 0;
}

.highlight {
  border-left: 5px solid #efefef;
  padding: 10px;
  margin-left: 10px;
}

.categories-list {
  max-width: 800px;

  ul {
    list-style-type: none;
    margin: 20px auto;
    overflow: auto;
    text-align: center;
  }

  li {
    display: inline-block;
    padding: 5px;
  }
}

.category {
  h2 {
    margin-top: 40px;

  }
}